<template>
  <b-container fluid="xl">
    <section-title class="pt-5">{{ title }}</section-title>
    <p class="text-uppercase font-weight-light text-center text-primary pb-4">
      {{ subtitle }}
    </p>
    <div class="my-4">
      <template v-for="(emp, index) in people">
        <Member
          :image="[emp.employees &&
          emp.employees.employee_stories.short_description_image &&
          emp.employees.employee_stories.short_description_image.sizes &&
          emp.employees.employee_stories.short_description_image.sizes.square_large ?
          emp.employees.employee_stories.short_description_image.sizes.square_large :
          '']"
          :position="emp.position"
          :name="emp.employees.post_title"
          :big="big(emp.layout)"
          :reverse="reverse(emp.layout)"
          :noDivider="index === people.length - 1"
        >
          <p
            class="text-primary text-small text-bold mt-4"
            v-html="emp.employees.employee_stories.short_description"
          ></p>
          <router-link
            class="btn btn-primary w-100 p-3 mt-auto text-small text-uppercase"
            :to="'/employee_story/' + emp.employees.ID"
            >{{buttonlabel}}</router-link
          >
        </Member>
      </template>
    </div>
  </b-container>
</template>

<script>
import SectionTitle from "../SectionTitle.vue";
import Member from "./Member.vue";
export default {
  components: { SectionTitle, Member },
  props: ["people", "title", "subtitle", "buttonlabel"],
  data: function () {
    return {
      backendResourceDomain: "",
    };
  },
  methods: {
    big(layout) {
      if (layout == "col_3_left_img" || layout == "col_3_right_img") {
        return false;
      } else {
        return true;
      }
    },
    reverse(layout) {
      if (layout == "col_3_right_img" || layout == "col_2_right_img") {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
