<template>
  <div v-if="!big" class="position-relative">
    <!-- Version with small image -->
    <div class="row smallContainer my-md-4">
      <div class="col-md-4 col-12 d-flex justify-content-center align-items-start" :class="reverse && 'order-md-2'">
        <img :src="image" alt="" class="img-fluid" v-if="image && image!=''"/>
        <div class="rectangle" :class="reverse && 'rectangle--reverse'" />
      </div>
      <div class="d-md-none w-100">
        <h2 class="text-bold text-primary text-center">{{ name }}</h2>
        <h3 class="text-primary text-center">{{ position }}</h3>
      </div>
      <div class="col-md-8 col-12 multi-column my-4 my-md-0" :class="reverse && 'order-1'">
        <div class="d-none d-md-block">
          <h2 class="text-bold text-primary text-center text-md-left">{{ name }}</h2>
          <h3 class="text-primary text-center text-md-left">{{ position }}</h3>
        </div>
        <slot />
        <a
          class="btn btn-primary w-md-100 p-3 mt-auto text-small text-uppercase mx-auto d-none d-xl-block"
          v-if="button"
          :href="button.href"
        >
          {{ button.text }}
        </a>
      </div>
    </div>
    <div v-if="button" class="w-100 text-center d-flex d-xl-none">
      <a class="btn btn-primary p-3 text-small text-uppercase mx-auto col-12 col-md-auto" :href="button.href">
        {{ button.text }}
      </a>
    </div>
    <div class="horizontalLine" v-if="!noDivider" />
  </div>

  <!-- Version with big image -->
  <div v-else>
    <div class="row">
      <div class="col-md-8 col-12" :class="reverse && 'order-md-2'">
        <img :src="image" alt="" class="img-fluid" v-if="image && image!=''" />
        <div class="rectangle" :class="reverse && 'rectangle--reverse'" />
      </div>
      <div class="col-md-4 col-12 d-flex flex-column" :class="reverse && 'order-1'">
        <div class="d-flex flex-column text-center text-md-start">
          <h2 class="text-bold text-primary">{{ name }}</h2>
          <h3 class="text-primary">{{ position }}</h3>
        </div>
        <div class="multi-column d-md-flex flex-column">
          <slot />
        </div>
        <div v-if="button" class="w-100 h-100 d-flex text-center">
          <a class="btn btn-primary p-3 text-small text-uppercase col-12 col-md-auto mt-auto" :href="button.href">
            {{ button.text }}
          </a>
        </div>
      </div>
    </div>
    <div class="horizontalLine" v-if="!noDivider" />
  </div>
</template>

<script>
export default {
  props: {
    big: {
      type: Boolean,
      required: false,
    },
    reverse: {
      type: Boolean,
      required: false,
    },
    image: {
      type: String,
      required: false,
    },
    name: {
      required: true,
    },
    position: {
      type: String,
      required: true,
    },
    noDivider: {
      type: Boolean,
      required: false,
    },
    button: {
      type: Object,
      required: false,
    },
  },
};
</script>

<style scoped lang="scss">
.smallText {
  font-size: 16px;
}
.horizontalLine {
  width: 75%;
  margin: 30px auto;
  height: 1px;
  background: black;
}
.rectangle {
  height: 32px;
  width: 90%;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  background: white;
  @media (min-width: 768px) {
    width: 32px;
    height: 285px;
    top: 30px;
    left: auto;
    right: 0;
    transform: none;
    bottom: auto;
    &--reverse {
      left: 0;
      right: auto;
    }
  }
  // @media (min-width: 768px) {
  //   width: 32px;
  //   height: 285px;
  //   max-height: 80%;
  //   right: 0;
  //   left: auto;
  //   transform: none;
  //   &--reverse {
  //     right: auto;
  //     left: 0;
  //   }
  //   &--top {
  //     top: 30px;
  //   }
  //   &--center {
  //     top: 50%;
  //     transform: translateY(-50%);
  //   }
  // }
}
@media (min-width: 576px) {
  .multi-column {
    column-count: 2;
  }
}
</style>
