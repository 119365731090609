<template>
  <main v-if="pageData.acf" class="main">
    <page-slider
      :slides="pageData.acf.banner_group.slides"
      v-if="pageData && pageData.acf && pageData.acf.show_banner"
    ></page-slider>
    <div class="py-7" v-else></div>

    <b-container fluid="xl" class="my-5">
      <SectionTitle>{{ pageData.acf.section_1_content.title }}</SectionTitle>
      <p
        class="text-primary text-uppercase text-center my-3"
        v-html="pageData.acf.section_1_content.text"
      ></p>
    </b-container>

    <blue-wave-container class="blue-wave">
      <b-container fluid="xl" class="my-5">
        <template v-for="t in pageData.acf.section_2_content">
          <h4 class="text-bold">{{ t.title }}</h4>
          <p class="my-5" v-html="t.text"></p>
        </template>
      </b-container>
    </blue-wave-container>

    <div
      class="worth-reading"
      v-if="pageData && pageData.acf && pageData.acf.section_3_active"
    >
      <div class="worth-reading--overlay">
        <b-container fluid="xl" class="worth-reading--container">
          <h3 class="text-center text-white">
            {{ pageData.acf.section_3_content.title }}
          </h3>
          <div class="row text-white my-5">
            <div class="col-12 text-center mt-5">
              <div
                class="text mt-auto pb-4"
                v-html="pageData.acf.section_3_content.text"
              ></div>
            </div>
          </div>
        </b-container>
      </div>
    </div>
    <div class="my-14" v-else></div>

    <b-container
      fluid="xl"
      class="section-white pt-5 pb-5 bg-white"
      v-if="pageData && pageData.acf && pageData.acf.section_4_active"
    >
      <SectionTitle class="pb-5">{{
        pageData.acf.section_4_content.title
      }}</SectionTitle>
      <b-row class="my-5">
        <b-col
          md="8"
          class="text-small"
          v-html="pageData.acf.section_4_content.text"
        ></b-col>
        <b-col md="3" class="offset-md-1">
          <product-wave
            :attachments="pageData.acf.section_4_content.files"
            v-if="pageData.acf.section_4_content.files"
            class="my-5"
          />
        </b-col>
      </b-row>
    </b-container>

    <b-container fluid class="py-4 bg-gray text-center text-primary" v-if="pageData && pageData.acf && pageData.acf.section_5_active">
      <b-container
        fluid="xl"
      >
        <h3 class="text-center text-primary mt-5 mb-3">
          {{ pageData.acf.section_5_content.title }}
        </h3>
        <p class="mb-5" v-html="pageData.acf.section_5_content.text"></p>

        <list-item
          v-for="item in pageData.acf.section_5_content.files"
          :key="item.title"
          :title="item.title"
          :subtitle="item.subtitle"
        >
          <div class="d-flex justify-content-end">
            <a
              class="
                text-white
                detail
                d-md-flex
                align-items-md-center
                text-center
                col-12 col-md-auto
                py-4
              "
              :href="backendResourceDomain + item.file.url"
            >
              download
              <span class="d-md-none">PDF</span>
              <img
                src="/images/icons/common/pdf.svg"
                class="d-none d-md-block ml-2"
              />
            </a>
          </div>
        </list-item>
      </b-container>
    </b-container>

    <b-container fluid class="py-4 bg-gray text-center text-primary" v-if="pageData && pageData.acf && pageData.acf.section_6_active">
      <b-container
        fluid="xl"
        v-if="pageData && pageData.acf && pageData.acf.section_6_active"
      >
        <h3 class="text-center text-primary my-5">
          {{ pageData.acf.section_6_content.title }}
        </h3>

        <ContactForm :id="pageData.acf.section_6_content.contact_form_id" />
      </b-container>
    </b-container>

    <OurPeople
      :people="pageData.acf.section_7_content.employees"
      :title="pageData.acf.section_7_content.title"
      :subtitle="pageData.acf.section_7_content.subtitle"
      :buttonlabel="pageData.acf.section_7_content.readmore_button_anchor"
    />

    <b-container fluid="xl">
      <router-link
        class="
        btn btn-primary
        p-3
        text-small text-uppercase
        w-100
        text-bold
        redirect-btn
        my-5
      "
        v-if="pageData &&
        pageData.acf &&
        pageData.acf.section_8_active &&
        pageData.acf.section_8_content &&
        pageData.acf.section_8_content.button &&
        pageData.acf.section_8_content.button.url &&
        pageData.acf.section_8_content.button.anchor"
        :to="pageData.acf.section_8_content.button.url"
        v-text="pageData.acf.section_8_content.button.anchor"
      >
      </router-link>
    </b-container>
  </main>
</template>

<script>
import axios from "@/axios";
import PageSlider from "../components/PageSlider";
import SectionTitle from "../components/SectionTitle.vue";
import BlueWaveContainer from "../components/BlueWaveContainer.vue";
import ListItem from "../components/ListItem.vue";
import FileInput from "../components/FileInput.vue";
import OurPeople from "../components/OurPeople/OurPeople.vue";
import ContactForm from "/src/components/ContactFormCareer.vue";
import ProductWave from "/src/components/ProductWave.vue";

export default {
  components: {
    PageSlider,
    SectionTitle,
    BlueWaveContainer,
    ListItem,
    FileInput,
    OurPeople,
    ContactForm,
    ProductWave,
  },
  data() {
    return {
      isLoading: true,
      backendResourceDomain: "",
      currentPageSlug: "career",
      pageData: [],
      form: {
        name: "",
        email: "",
        country: null,
        message: "",
        file: null,
      },
      status: false,
    };
  },
  metaInfo() {
    if (!this.isLoading && this.pageData.acf.section_seo) {
      return {
        title: this.pageData.acf.section_seo.meta_title,
        meta: [
          {
            name: "description",
            content: this.pageData.acf.section_seo.meta_description,
          },
          {
            name: "keywords",
            content: this.pageData.acf.section_seo.meta_keywords,
          },
        ],
      };
    }
  },
  async created() {
    this.getPageDataBySlug();
  },
  watch: {
    $route(to, from) {
      this.getPageDataBySlug();
    },
  },
  methods: {
    getPageDataBySlug: function () {
      axios
        .get("custom-pages/" + this.$route.meta.slug)
        .then((response) => {
          // console.log("carreer", response);
          this.pageData = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fileChange(ev) {
      this.form.file = ev.target.files[0];
    },
    resetForm() {
      this.form = {
        name: "",
        email: "",
        country: null,
        message: "",
        file: null,
      };
      this.status = false;
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    onSubmit() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return;
        }

        alert("Form submitted!");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/config";
$waveHeight: 60px;
.blue-wave {
  margin-bottom: -120px;
}
.offer {
  margin-top: -$waveHeight;
  position: relative;
  &-mask {
    background: rgba($blue-50, 0.8);
    padding-top: $waveHeight;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  &-container {
    margin: 80px auto;
    &_title {
      margin-bottom: 90px;
    }
  }
}
.detail {
  font-size: 16px;
  cursor: pointer;
}

.worth-reading {
  background: url("/images/worth-reading.jpg");
  background-position: center;
  background-repeat: no-repeat;
  min-height: 733px;
  background-size: cover;
  z-index: -1;
  &--overlay {
    background: rgba(12, 37, 82, 0.6);
    height: 100%;
    width: 100%;
  }
  &--container {
    padding-top: 168px;
    padding-bottom: 100px;
    .text {
      line-height: 60px;
      ul {
        padding-left: 15px;
      }
    }
  }
  &__divider {
    height: 1px;
    background: white;
    margin: 40px 0;
  }
}
.section-white {
  margin-top: -100px;
}
</style>
